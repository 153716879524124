import React, { Fragment } from 'react';
import BackgroundImage from "gatsby-background-image";
import { Helmet } from 'react-helmet';

const Terms = ({ termsData }) => {
    const { contentfulTermsOfServices } = termsData;
    const contentfulTermsOfServicesRaw = JSON.parse(
        contentfulTermsOfServices?.htmlContents?.raw
    );
    return (
        <Fragment>
            <Helmet>
                <title>Terms of Service</title>
            </Helmet>
            <div className='banner-blue-background'>
                <div>
                    <h1 className="heading">{contentfulTermsOfServices?.title}</h1>
                </div>
            </div >
            <section className="terms-of-services-section" dangerouslySetInnerHTML={{ __html: contentfulTermsOfServicesRaw?.content?.[0].content?.[0]?.value }} />

        </Fragment >
    )
}

export default Terms;