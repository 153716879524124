import { graphql } from "gatsby";
import React from "react";
import Terms from "../components/terms";

const TermsAndServices = ({ data }) => {
  return <Terms termsData={data} />
};

export default TermsAndServices;

export const query = graphql`
  {
  contentfulTermsOfServices(htmlContents: {}) {
    title
    htmlContents {
      raw
    }
    image {
      fluid(maxWidth: 1000, quality: 100) {
        ...GatsbyContentfulFluid
      }
    }
  }
    contentfulCookie {
      cookieText {
       cookieText
      }
    }
    allContentfulNavigationSliderContent {
      nodes{
        title{
          title
        }
        redirectUrl,
        link
      }
    } 
  }
`;
